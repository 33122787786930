// AnimaraPage.js
import React, { useState, useEffect } from "react";
import AnimaraMap from "./AnimaraMap";
import Navbar from "../../component/NavBar";
import ToggleMap from "../../component/ToggleMap"; 
import AudioPlayerPlaylist from '../../component/AudioPlayerPlaylist';

const AnimaraPage = () => {
  const [isShowingPins, setIsShowingPins] = useState(true);
  const [isShowingPinToggle, setIsShowingPinToggle] = useState(true);
  const [notifyContent, setNotifyContent] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const detectMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };
  
  window.addEventListener("resize", detectMobile);
  
  useEffect(() => {
    detectMobile();
    return () => {
      window.location.reload();
    };
  }, []); // Empty dependency array means it runs once on mount, and cleanup on unmount

  useEffect(() => {
    setTimeout(() => {
      if (isPageReady) {
        setCloseLoading(true);
        setTimeout(() => {
          setNotifyContent(true);
        }, 800);
      }
    }, 1200);
  }, [isPageReady]);
  
  const togglePins = (isShowing) => {
    setIsShowingPins(isShowing);
  };

  const playlist = [
    { name: 'Beats of the Islands - Yaland Vibes', avatar: '/assets/mainV2/audio-player-avatar-01.webp', src: 'https://storage.animara.world/Beats%20of%20the%20Islands%20-%20Yaland%20Vibes.mp3' },
    { name: 'Whispers of the Breeze - Elysian Echoes', avatar: '/assets/mainV2/audio-player-avatar-02.webp', src: 'https://storage.animara.world/Rhythms%20in%20Bloom%20-%20Animus%20Pulse.mp3' },
    { name: 'Rhythms in Bloom - Animus Pulse', avatar: '/assets/mainV2/audio-player-avatar-03.webp', src: 'https://storage.animara.world/Whispers%20of%20the%20Breeze%20-%20Elysian%20Echoes.mp3' },
  ]

  return (
    <div className="bg-black">
      <div className={`fixed bg-black w-full h-full flex items-center justify-center overflow-y-hidden ${notifyContent ? 'hidden' : 'block'} transition-all duration-700 ${closeLoading ? 'opacity-0' : 'opacity-100'}`}>
        <img
          className={`w-[300px] h-auto mt-20 animate-pulse`}
          src="/assets/mainV2/hero-loading.png"
          alt="hero loading"
        />
      </div>

      <div className={`relative flex flex-col items-center w-full h-screen overflow-hidden transition-all duration-700 ${closeLoading ? 'opacity-100' : 'opacity-0'}`}>
        <Navbar className="z-10" />
        {isShowingPinToggle?
          <ToggleMap togglePins={togglePins} />
          :
          <></>
        }
        <AnimaraMap isShowingPins={isShowingPins} setIsPageReady={setIsPageReady} isMobile={isMobile} setIsShowingPinToggle={setIsShowingPinToggle}/>
      </div>
      
      <AudioPlayerPlaylist playlist={playlist}/>

    </div>
  );
};

export default AnimaraPage;
