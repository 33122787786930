import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const MainV2Hero = ({ videoRef, hasEndedOnce, setHasEndedOnce, setIsPageReady }) => {
  const { t } = useTranslation();
  const heroInfoRef = useRef(null);

  const makeVisible = () => {
    if (heroInfoRef.current) {
      // Remove opacity-0 and add opacity-100
      heroInfoRef.current.classList.remove('opacity-0');
      heroInfoRef.current.classList.add('opacity-100');
    }
  };

  const handleVideoEnd = () => {
    if (!hasEndedOnce) {
      makeVisible();
      setHasEndedOnce(true);  // Mark that the video has ended once
    }
  };

  const isVideoLoaded = useCallback(() => {
    setIsPageReady(true);
    videoRef.current.play();
  }, [setIsPageReady, videoRef]);

  useEffect(() => {
    videoRef.current.play();
  }, [videoRef]);

  return (
    <div className="relative w-auto lg:w-screen max-width-none lg:max-w-[1920px] bg-black h-screen lg:h-screen  overflow-hidden flex flex-col justify-end items-center bg-mainv2-hero-gradient">
      
      {/* Start and End Fade Out Overlays */}
      <div className="block absolute top-0 left-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-r from-[#000000] to-transparent pointer-events-none z-[1001]"></div>
      <div className="block absolute top-0 right-0 w-[1rem] lg:w-[100px] h-full bg-gradient-to-l from-[#000000] to-transparent pointer-events-none z-[1001]"></div>

      <div className="absolute h-[650px] lg:h-full inset-0">
        <video
          className="w-full h-full object-cover"
          autoPlay
          muted
          playsInline
          preload="auto"
          onCanPlay={() => {
            videoRef.current.pause();
            isVideoLoaded();
          }}
          onEnded={handleVideoEnd}
          onError={(err)=>{
            console.log(err);
          }}
          ref={videoRef}
        >
          <source src="https://storage.animara.world/hero-video.mp4" type="video/mp4" />
        </video>
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent z-10 pointer-events-none"></div>
      </div>
      
      <div ref={heroInfoRef} className="flex flex-col justify-end items-center w-[80%] pb-[6rem] opacity-0 transition-opacity duration-300 z-30">
        <div className="flex container">
          <h1
            className="text-[#FFFFFF] text-7xl lg:text-8xl xl:text-9xl font-normal font-bigNoodle lg:tracking-wider uppercase mr-2"
            style={{ textShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)' }}
          >
            {t("main game title 1")}&nbsp;
          </h1>
          <h1
            className="text-[#ffc75a] text-7xl lg:text-8xl xl:text-9xl font-normal font-bigNoodle lg:tracking-wider uppercase"
            style={{ textShadow: '5px 5px 10px rgba(0, 0, 0, 0.5)' }}
          >
            {t("main game title 1.5")}
          </h1>
        </div>

        <div className="hidden lg:flex container justify-between items-center gap-[2rem]">
          <p className="text-[#C5C5C5] text-lg font-medium font-outfit lg:w-[35%]">
            {t("main game description")}
          </p>
          <div className="flex container gap-[10px] justify-start items-center">
            <div className="flex container items-center">
              <h1 className="text-[#FFFFFF] text-7xl lg:text-8xl xl:text-9xl font-normal font-bigNoodle lg:tracking-wider uppercase text-nowrap mr-2">
                {t("main game title 2")}&nbsp;
              </h1>
              <h1 className="text-[#ffc75a] text-7xl lg:text-8xl xl:text-9xl font-normal font-bigNoodle lg:tracking-wider uppercase text-nowrap">
                {t("main game title 3")}
              </h1>
            </div>
            <a
              type="button"
              href="https://app.animara.world/login"
              target="_blank"
              rel="noreferrer"
              className="relative w-[8rem] scale-105 hover:scale-150 transition-all duration-300 "
            >
              <img
                className="w-[100%] rotate-infinite"
                src="/assets/mainV2/hero-button-cover.webp"
                alt="button cover"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  className="w-[65%]"
                  src="/assets/mainV2/hero-button-arrow.webp"
                  alt="button arrow"
                />
              </div>
            </a>
          </div>
        </div>

        <div className="flex flex-col lg:hidden container items-center gap-[0.5rem]">
          <div className="flex container gap-[15px] items-center">
            <h1 className="text-[#FFFFFF] text-7xl font-normal font-bigNoodle lg:tracking-wider uppercase text-nowrap">
              {t("main game title 2")}
            </h1>
            <h1 className="text-[#ffc75a] text-7xl font-normal font-bigNoodle lg:tracking-wider uppercase text-nowrap">
              {t("main game title 3")}
            </h1>
          </div>
          <div className="flex container gap-[10px] justify-start items-center">
            <p className="text-[#C5C5C5] text-xs font-medium font-outfit">
              {t("main game description")}
            </p>
            <div className="relative w-[8rem] ">
              <img
                className="w-[100%] rotate-infinite"
                src="/assets/mainV2/hero-button-cover.webp"
                alt="button cover"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  className="w-[65%]"
                  src="/assets/mainV2/hero-button-arrow.webp"
                  alt="button arrow"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default MainV2Hero;
