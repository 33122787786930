import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainV2Hero from './MainV2Hero';
import MainV2Marketplace from './MainV2Marketplace';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ToTopButton from '../../component/ToTopButton'; // Add the import here
import MainV2EarnEvent from './MainV2EarnEvent';
import MainV2TokenValue from './MainTokenValue';
import MainV2Referal from './MainV2Referal';
import MainV2Supporter from './MainV2Supporter';
import MainV2Footer2 from './MainV2Footer2';
import MainTokenValueMobile from './MainTokenValueMobile';
import AudioPlayerPlaylist from '../../component/AudioPlayerPlaylist';

const MainV2Page = () => {

  const videoRef = useRef(null);
  const [hasEndedOnce, setHasEndedOnce] = useState(false);
  const [notifyContent, setNotifyContent] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);

  // TODO: update with aws link confirmed music
  // const playlist = [
  //   { name: 'Stellar Horizons - Celestial Drifters', avatar: '/assets/mainV2/audio-player-avatar-map-01.webp', src: 'https://storage.animara.world/Echoes%20of%20the%20Nebula%20-%20Galactic%20Voyager.mp3' },
  //   { name: 'Echoes of the Nebula - Galactic Voyager', avatar: '/assets/mainV2/audio-player-avatar-map-02.webp', src: 'https://storage.animara.world/Stellar%20Horizons%20-%20Celestial%20Drifters.mp3' },
  //   { name: 'Astral Waves', avatar: '/assets/mainV2/audio-player-avatar-map-03.webp', src: 'https://storage.animara.world/Astral%20Waves.mp3' },
  // ];

  useEffect(() => {
    setTimeout(() => {
      if (isPageReady) {
        setCloseLoading(true);
        setTimeout(() => {
          setNotifyContent(true);
        }, 1000);
      }
    }, 1800);
  }, [isPageReady]);

  return (
    <div className="bg-black">
      <div className={`fixed bg-black w-full h-full flex items-center justify-center overflow-y-hidden ${notifyContent ? 'hidden' : 'block'} transition-all duration-700 ${closeLoading ? 'opacity-0' : 'opacity-100'}`}>
        <img
          className={`w-[300px] h-auto mt-24 animate-pulse`}
          src="/assets/mainV2/hero-loading.png"
          alt="hero loading"
        />
      </div>

      <div className={`relative bg-black flex flex-col items-center justify-center overflow-x-hidden transition-all duration-700 ${closeLoading ? 'opacity-100' : 'opacity-0'}`}>
        <Navbar landingVideoEnded={hasEndedOnce} isSticky />
        <MainV2Hero videoRef={videoRef} hasEndedOnce={hasEndedOnce} setHasEndedOnce={setHasEndedOnce} setIsPageReady={setIsPageReady} />
        <MainV2EarnEvent />
        {/* <MainV2Trailer /> */}
        {/* <MainV2Character /> */}
        <MainV2Marketplace />
        {/* <MainV2ReferalCashback /> */}
        {/* <MainV2AniWalk /> */}
        <MainV2TokenValue />
        <MainTokenValueMobile />
        {/* <MainV2Matter /> */}
        <MainV2Referal />
        <MainV2Supporter />
        <MainV2Footer2 />
        {/* <MainV2Scene /> */}
        {/* <MainV2Roadmap /> */}
        {/* <MainV2Partners /> */}
        {/* <MainV2Footer /> */}
        <Footer />
        <ToTopButton /> {/* Add the button here */}
      </div>

      {/* <AudioPlayerPlaylist playlist={playlist}/> */}
    </div>
  );
}

export default MainV2Page;
