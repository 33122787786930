import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const MainTokenValueMobile = () => {
  const { t } = useTranslation();

  const formatStringBreaks = (text) => {
    // Split the text by the newline character
    return text.split('\n').map((part, index) => (
      <React.Fragment key={index}>
        {part}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="flex flex-col items-center lg:hidden relative w-full overfow-y-hidden my-16 mb-24">
      <div className="absolute inset-0 z-[2]">
        <img
          className="w-full h-full object-fill"
          src="/assets/mainV2/mainV2-tokenvalue-mobile-bg.webp"
          alt="Background"
        />
      </div>
      <div className="relative w-[80%]">
        <div className="flex flex-col items-center justify-center pointer-events-none z-[100]">
          <img
            className="w-2/3 h-auto z-[200]"
            src="/assets/mainV2/Coin_Turntable.png"
            alt="Background"
          />
          <div className="flex flex-col items-center justify-center z-[200]">
            <h1 className="text-[#FFC85A] text-center text-[12.5vw] lg:text-5xl font-normal font-bigNoodle transition-opacity duration-1000 lg:mt-[0rem]">
              {t('anitoken value title')}
            </h1>
            <p
              className="text-[#FFFFFF] text-center text-sm font-medium font-outfit transition-opacity duration-1000 lg:mt-[1rem]"
            >
              {t("anitoken value desc")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-transparent items-center justify-center z-[100]">
        <div className="flex flex-col w-[85%] items-center gap-[3rem] my-[5rem]">
          <div className="flex flex-col items-center">
            <h1 className="text-center text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
              {formatStringBreaks(t('anitoken value point title 1'))}
            </h1>
            <p className="text-center text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-1000 mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 1'))}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-center text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
              {formatStringBreaks(t('anitoken value point title 2'))}
            </h1>
            <p className="text-center text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-1000 mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 2'))}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-center text-[#FFF7EA] text-4xl font-normal font-bigNoodle transition-opacity duration-1000">
              {formatStringBreaks(t('anitoken value point title 3'))}
            </h1>
            <p className="text-center text-[#C5C5C5] text-sm font-normal font-outfit transition-opacity duration-1000 mt-[0.5rem]">
              {formatStringBreaks(t('anitoken value point desc 3'))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTokenValueMobile;