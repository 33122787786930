import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation } from "react-router-dom";
import PrivacyPolicyContent from './PrivacyPolicyContent';
import Navbar from '../../component/NavBar';
import Footer from '../../component/Footer';
import ToTopButton from '../../component/ToTopButton';

const PrivacyPolicyPage = () => {
  const [notifyContent, setNotifyContent] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const location = useLocation();
  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      if (isPageReady) {
        setCloseLoading(true);
        setTimeout(() => {
          setNotifyContent(true);
        }, 700);
      }
    }, 1000);
  }, [isPageReady]);

  return (
    <div className="bg-black">
      <div className={`fixed bg-black w-full h-full flex items-center justify-center overflow-y-hidden ${notifyContent ? 'hidden' : 'block'} transition-all duration-700 ${closeLoading ? 'opacity-0' : 'opacity-100'}`}>
        <img
          className={`w-[300px] h-auto mt-20 animate-pulse`}
          src="/assets/mainV2/hero-loading.png"
          alt="hero loading"
        />
      </div>

      <div className={`relative flex flex-col bg-black items-center justify-center overflow-x-hidden transition-all duration-700 ${closeLoading ? 'opacity-100' : 'opacity-0'}`}>
        <Navbar isSticky/>
        <PrivacyPolicyContent setIsPageReady={setIsPageReady} />
        <Footer />
        <ToTopButton />
      </div>
    </div>
  )
}

export default PrivacyPolicyPage;